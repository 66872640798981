<template>
  <main class="container">
    <div class="header h3 mt-2">
          Listado de Conocimientos
        <button class="btn-2 float-end" @click="gotoDiverseProducts()"><i class="fas fa-plus me-2"/> Crear Nuevo</button>
    </div>

    <!-- ADD NEW MODAL showNewModalContract -->
    <b-modal id="modal-2" title="Agregar Contrato" class="p-3" centered v-model="showNewModalContract" hide-footer>
        <form @submit.prevent="saveContract()" class="mx-3">
            <label class="text-green-2">Número de contrato:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="row.document">
            
            <div class="col-12 text-left mt-4 mb-4">
                <input accept="jpg,jpeg,png,PNG" type="file" id="file" @change="fileSelected()"/>
                <!-- <button class="btn btn-primary" type="button" :disabled="busy" @click="processFile()">
                    <span v-if="busy" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Subir datos
                </button> -->
            </div>

            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showNewModal=false">Cancelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>
    <!-- ADD NEW MODAL Responsability -->
    <b-modal id="modal-responsability" title="Agregar Responsable" class="p-3" centered v-model="showNewModalResponsability" hide-footer>
        <form @submit.prevent="saveResponsability()" class="mx-3">

            <label class="text-green-2">Nombre:</label>
            <select @change="employeeSel($event)" class="form-control select  mb-3" required v-model="row.employee">
                <option :value="undefined" disabled>* seleccione</option>
                <option :value="employee" v-for="employee in employeesList" :key="employee.id">{{employee.name}}</option>
            </select>            

            <label class="text-green-2">NIT:</label>
            <input type="text" readonly class="form-control border-secondary mb-4" required v-model="row.nit"> 

            <label class="text-green-2">Número de documento:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="row.documentId">
            <!--<input type="text" class="form-control border-secondary mb-2" required v-model="newItem.name">
            
            <label class="text-green-2">NIT:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newItem.nit"> -->
            
            <!-- <label class="text-green-2">Email:</label>
            <input type="text" class="form-control border-secondary mb-4" required v-model="newItem.email"> -->

            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showNewModalResponsability=false">Cancelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-2 mx-1 " 
         style="min-height:75vh; background:rgba(0,0,0,0.5);">
        
        <div class="col">            
            <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(creationDate)="data">
                     <span>{{$moment(data.item.creationDate).format('DD-MM-YYYY')}}</span>
                </template>
                 <template #cell(acciones)="data">
                    <div>                        
                        <b-button v-b-tooltip.hover.lefttop="'Ver detalle'" @click="$router.push('detailsDiverseProduct/'+data.item.id)" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-file-alt"></i></b-button>
                        <b-button v-b-tooltip.hover.lefttop="'Agregar Tarjeta de responsabilidad'" @click="row = data.item;showNewModalResponsability= true" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-user"></i></b-button>
                        <b-button v-b-tooltip.hover.lefttop="'Agregar Contrato'" @click="row = data.item;showNewModalContract= true" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-file-signature"></i></b-button>
                    </div>
                </template>

            </b-table>

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[],
        fields: [
          { key: "id", label:"Código"},
          { key: "creationDate", label:"Fecha Salida"},
          { key: "creationDate", label:"Fecha Salida"},
          { key: "receptionDate", label:"Fecha entrega"},
          { key: "sendername", label:"Emisor"},
          { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 10,
        currentPage: 1,

        newItem:{},
        showNewModal:false,
        selectedItem:{},
        showEditModal:false,
        table: 'transfers',
        showNewModalResponsability: false,
        employeesList: [],
        row:{},
        showNewModalContract: false
    }
},
async mounted(){
    await this.getData()
    await this.getEmployeesList()
},
methods:{
    async employeeSel(e){
        this.row.nit = this.row.employee.nit
        this.row.employeeId = this.row.employee.id
    },
    async getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = await function () {
            // console.log(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
        return reader
    },
    async saveContract(){
        let file = document.getElementById('file').files[0]
        let res64 = await this.getBase64(file)
        console.log('1')
        setTimeout(async () => {
            console.log('2')
            let resultaBase64 =res64.result
            console.log('---->',resultaBase64);
            console.log('3')
            this.row.picture = resultaBase64
            let res = await this.$store.dispatch('post', {path: 'transfers/newContract' , data: this.row});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.showNewModalContract=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        }, 2000);
    },
    async fileSelected(){

    },
    async saveResponsability(){
        try {
            console.log(this.row)
            // let employeeId = this.row.employee.id
            // this.row.employeeId = employeeId
            delete(this.row.employee)
            let res = await this.$store.dispatch('post', {path: 'transfers/newResponsability' , data: this.row});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.showNewModalResponsability=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error--->', error)
        }        
    },
    async getEmployeesList(){
        try {
            let res = await this.$store.dispatch('get', {path: 'employees/getAll'});
            if (res.length >0){
                this.employeesList = res
            }
        } catch (error) {
            this.employeesList = []
            console.error('error', error)
        }
    },
    gotoDiverseProducts(){
        this.$router.push('diverseProducts')
        // this.showEditModal=true;
        // this.selectedItem=data.item
    },
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getAllByType/2'});
            //console.log(res)
            if (res.length >0){
                this.items = res
            }
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
    },       
    async postData(){
        try {
            let res = await this.$store.dispatch('post', {path: this.table + '/new' , data: this.newItem});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.getData()
                this.showNewModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error--->', error)
        }        
    },
    async putData(){
        try {
            let data = Object.assign({} , this.selectedItem) 
            delete(data.id)
            let res = await this.$store.dispatch('put', {path: this.table + '/modify/'+ this.selectedItem.id , data: data});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.showEditModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error--->', error)
        }
    },       
    async deleteItem(item){
        const {isConfirmed} = await this.$swal({
            title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
            icon:"question",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            reverseButtons: true

        })
        if(isConfirmed){
            try {
                let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.getData()
                    this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                }
            } catch (error) {
                this.items = []
                console.error('error--->', error)
            }                    
        }
    }
}
}
</script>

<style>

</style>